import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { AiService } from 'src/app/services/AI/ai.service';
import { Router,ActivatedRoute } from '@angular/router';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import * as pluginOutlabel from 'chartjs-plugin-piechart-outlabels';

@Component({
  selector: 'app-group-page',
  templateUrl: './group-page.component.html',
  styleUrls: ['./group-page.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),

    trigger('slideFromLeft', [
      transition('void => *', [
        style({
          transform: 'translateX(-50vw)',
          opacity:0,
          // width:'50px',
          //height:'50px'

        }),
        animate('600ms cubic-bezier(.68,.16,.26,.93)')
      ])
    ]),


    trigger('slideFromBottom', [

      transition('void => *', [
        style({
          transform: 'translateY(30vh)',
          opacity:0,
        }),
        animate('600ms cubic-bezier(.99,0,.45,.71)')
      ])

    ]),
  ],
})
export class GroupPageComponent implements OnInit, OnDestroy, AfterViewInit {
  /////////////////////////////////////////////////////////////////////////////////

  /* Table */
  // importing matpaginator and mat sort element
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;


//////////////////////////////////////////////////////////////////////////

  constructor(
    public aiService:AiService,
    public route: ActivatedRoute,
    public router: Router,
    private changeDetectorRefs: ChangeDetectorRef
  ) {
    // console.log(this.paginator);
    //this.dataSource = new MatTableDataSource(this.scans);
  }



/////////////////////////////////////////////////////////////////////////
/* Variables */
//RENAME ALL VARS accordingly
  sessionId;                // Session ID of the selected session
  groupId;                  // Group ID of the selected group
  session;                  // Session info of the current session
  group;                    // Group info of the current group
  scans;                    // An array for containing all the scans of the selected group
  private scansSub:Subscription
  private sessionSub:Subscription
  private groupSub:Subscription
  bodyScore = null;         // body_condition_score for all the groups in the session
  fatScore = null;          // fat_score for all the groups in the session
  inseminatedPercent=null;     // Percentage inseminated for the group
  ovarianStatus=[0,0,0,0]   // Ovarian status sum for the session arranged in an array  //  i.e: [follicleSum,chSum,clSum,not_cyclingSum]
  uterineTone=[0,0,0]       // Uterine status sum for the session arranged in an array  //  i.e: [turgidSum,flaccidSum,abnormalSum]
  googleApiSrc;             // Contains the constructed google API for the location of the selected session
  showMap=false;            // Toggle for showing map on the google map on the browser
  isLoading=true;           // Toggle for rendering the html contents
  showOvarian=false;
  showUterine=false;
  showCharts = false;
  isScanData = false;
  missingMessage ='N/A';
  showMissingScans = false;
  groupTimeStamp;
  headerArrowStatus=[];


  /* Variables for table */
  expandedElement;
  displayedColumns: string[] = ['ewe_rfid_scanned_at','spacer','ewe_id', 'internal_fat_score','body_condition_score', 'ovarian_status', 'uterine_tone','inseminated','arrow'];
  dataSource;

  /* Variables for the different graphs (required for bar-chart)*/

  // Pie-Chart
  ovarianLabel = [ 'FOLLICLE', 'CH', 'CL', 'NOT_CYCLING' ];
  uterineLabel = ['TURGID', 'FLACCID', 'ABNORMAL'];

  // Common plots
  public colorPallete = ["#fc7978","#c299fc","#b2fcff"];


  //////////////////////////////////////////////////////////////////////////////////////


 /* Pie Chart*/
// pieChartData -> Ovarian Status
// pieChartData2 -> Uterine Tone

public pieChartOptions: ChartOptions = {
  layout: {
    padding: {
        left: 0,
        right: 0,
        top: 35,
        bottom: 35
        }
    },
  elements: {
    arc: {
      borderWidth: 0
    }
  },
  responsive: true,
  legend: {
    display:false,
    position: 'left',
  },
  plugins: {
    outlabels: {
      display:function(context){
        if(context.dataset.data[context.dataIndex]>0){
          return true
        }else{
          return false
        }
      },
      text: ' %l %p',
      color: 'white',
      stretch: 25,
      font: {
          resizable: true,
          minSize: 12,
          // maxSize: 18
      }
    },
    datalabels: {
      display:false,
      formatter: (value, ctx) => {
        const label = ctx.chart.data.labels[ctx.dataIndex];
        if(value){
          return label;
        }else{
          return null;
        }
      },
      anchor:'end',clamp:true
    },
  }
};
public pieChartType: ChartType = 'doughnut';
public pieChartLegend = true;
public pieChartPlugins = [pluginDataLabels,pluginOutlabel];
public pieChartColors = [
  {
    backgroundColor: this.colorPallete
  },
];
public pieChartLabels = this.ovarianLabel;
public pieChartData: number[] =  this.ovarianStatus;
public pieChartLabels2 = this.uterineLabel;
public pieChartData2: number[] =  this.uterineTone;

  /////////////////////////////////////////////////////////////////////////////////////////



  ngOnInit() {

    this.loadingTimeout();

    // Selecting the session-id from the params
    this.sessionId=this.route.snapshot.params["session-id"];
    // Selecting the group-id from the params
    this.groupId=this.route.snapshot.params["group-id"];



    // HTTP CALL HERE (GET_SESSION) and (GET_GROUP) getSession(this.sessionId) getGroup(this.groupId)
    this.aiService.getSession(this.sessionId);
    this.sessionSub = this.aiService.getSessionObs()
    .subscribe(sessionData=>{
      this.session = sessionData;

      this.aiService.getGroup(this.sessionId,this.groupId);
      this.groupSub = this.aiService.getGroupObs()
      .subscribe(groupData=>{
        this.group = groupData;
        console.log(this.group);
        this.groupTimeStamp =  moment.utc(this.group.created_at).format('DD-MM-YYYY  HH:mm:ss');

        this.aiService.getScans(this.sessionId,this.groupId);
        this.scansSub = this.aiService.getScansObs()
        .subscribe(scansData=>{
          // scansData[0].inseminated=1;
          // scansData[2].inseminated=0;
          // scansData[0].ovarian_status='CH';
          // scansData[2].ovarian_status='CL';
          this.scans = scansData;
          console.log(this.scans);

          this.isScanData = (this.scans[0].scan_id === null)?false:true;

          this.showMissingScans = this.isScanData?false:true;

          this.scans.forEach(scan=>{
            if(scan.ewe_rfid_scanned_at){
              scan.ewe_rfid_scanned_at=moment(scan.ewe_rfid_scanned_at,'LTS').format('HH:mm:ss');
            }

          });

          this.dataSource = new MatTableDataSource(this.scans);
          this.initialisation();


          /*Initialisation */
          // For table

          this.refreshTable();
          this.isLoading=false;

        })
      })
    })



    // Should include average av_body_cond and av_fat + (ovarian_status + uterine_tone)---> Distribution
    // It should be included as a property of the group


    /* Temporary section until av_body_cond and av_fat are not in (GET_SCANS) scans is an array all scans in the selected group */


    //HTTP CALL HERE (GET_SCANS) this.scans=getScans(group.id)


  }

  ngAfterViewInit(){
    //console.log(this.paginator);
  }

  loadingTimeout(){
    setTimeout(()=>{

      if(!this.isScanData&&!this.showUterine&&!this.showOvarian){
       this.showMissingScans = true;
      }
     }
      , 2000);
  }

  reloadData(){
    this.ovarianStatus=[0,0,0,0];
    this.uterineTone=[0,0,0];
    this.aiService.getSession(this.sessionId);
    this.showMissingScans=false;
    this.loadingTimeout();
  }

  refreshTable(){

    this.displayedColumns.forEach(()=>{
      this.headerArrowStatus.push(true);
    })
    // Splice higher index first as the indexes are altered
    if(!this.showUterine){
      this.displayedColumns.splice(6,1);
    }
    if(!this.showOvarian){
      this.displayedColumns.splice(5,1);
    }
    if(!this.group.average_body_condition_score){
      this.displayedColumns.splice(4,1);
    }
    if(!this.group.average_internal_fat_score){
      this.displayedColumns.splice(3,1);
    }


    this.dataSource.sort = this.sort;
    this.paginator.showFirstLastButtons=true;
    this.dataSource.paginator = this.paginator;
  }

  onClickHeader(columnName:string){
    let index;
    index=this.displayedColumns.indexOf(columnName);
    this.headerArrowStatus[index]=false;
  }

  checkHeader(columnName:string){
    let index;
    index=this.displayedColumns.indexOf(columnName);
    return this.headerArrowStatus[index];
  }


  ngOnDestroy(){
    this.scansSub?this.scansSub.unsubscribe():0;
    this.sessionSub?this.sessionSub.unsubscribe():0;
    this.groupSub?this.groupSub.unsubscribe():0;
    this.aiService.clear();
  }

  doNothing(){}

  initialisation(){
    /* Part of temporary solution that makes an array for body+fat scores and distribution of ovarian_stat+uterine_tone */

    let bodyConditionSum=0;
    let bodyConditionTotal=0;
    let fatSum=0;
    let fatTotal=0;
    let inseminated = 0;
    let inseminatedCount = 0;
    let inseminatedExists=false;

    for (let j=0;j<this.scans.length;j++){

      // Summing body conditons and fat scores

      // if(this.scans[j].body_condition_score){
      //   bodyConditionSum+=parseFloat(this.scans[j].body_condition_score);
      //   bodyConditionTotal++;
      // }

      // if(this.scans[j].internal_fat_score){
      //   fatSum+=parseFloat(this.scans[j].internal_fat_score);
      //   fatTotal++;
      // }


      /* Ovarian Status*/
      if(this.scans[j].ovarian_status){
        this.showOvarian=true;
        this.showCharts=true;
        switch(this.scans[j].ovarian_status){
          case 'FOLLICLE':{
              this.ovarianStatus[0]++;
            break;
          }
          case 'CH':{
            this.ovarianStatus[1]++;
          break;
          }
          case 'CL':{
            this.ovarianStatus[2]++;
          break;
          }
          case 'NOT_CYCLING':{
            this.ovarianStatus[3]++;
          break;
          }
          case 'default':{
            break;
          }
        }
      }


      /* Uterine Tone*/
      if(this.scans[j].uterine_tone){
        this.showUterine=true;
        this.showCharts=true;

        switch(this.scans[j].uterine_tone){
          case 'TURGID':{
              this.uterineTone[0]++;
            break;
          }
          case 'FLACCID':{
            this.uterineTone[1]++;
          break;
          }
          case 'ABNORMAL':{
            this.uterineTone[2]++;
          break;
          }
          case 'default':{
            break;
          }
        }
      }


      /* Insemination*/
      if(this.scans[j].inseminated!==null){
        if(!inseminatedExists){
          inseminatedExists=true;
        }
        inseminatedCount++;
        inseminated += (this.scans[j].inseminated===1)?1:0
      }

    }

    /* Body Condition, Fat & Insemination */
    // if(bodyConditionSum>0){
    //   this.bodyScore= bodyConditionSum/bodyConditionTotal;
    //   console.log(this.bodyScore);
    // }
    // if(fatSum>0){
    //   this.fatScore = fatSum/fatTotal;
    // }

    this.bodyScore=this.group.average_body_condition_score;
    this.fatScore=this.group.average_internal_fat_score;

    inseminatedExists?this.inseminatedPercent=Math.round(inseminated/inseminatedCount*100):0;


    /* Strikeout pie chart when no data */
    if(!this.showUterine){
      for (let i=0; i<this.uterineTone.length; i++){
        if (this.uterineTone[i]===0){
          this.uterineTone[i]=undefined;
        }
      }
    }

    if(!this.showOvarian){
      for (let i=0; i<this.ovarianStatus.length; i++){
        if (this.ovarianStatus[i]===0){
          this.ovarianStatus[i]=undefined;
        }
      }
    }



    /* Removes the section from pie chart */
    if(this.showUterine){
      for (let i=0; i<this.uterineTone.length; i++){
        if (this.uterineTone[i]===0){
          this.uterineTone.splice(i,1);
          this.uterineLabel.splice(i,1);
          i=0;
        }
      }
    }

    if(this.showOvarian){
      for (let i=0; i<this.ovarianStatus.length; i++){
        if (this.ovarianStatus[i]===0){
          this.ovarianStatus.splice(i,1);
          this.ovarianLabel.splice(i,1);
          i=0;
        }
      }
    }


  }



}













