import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import Amplify, {Auth, API} from 'aws-amplify';
import amplify from './aws-exports';

// amplify["Auth"] = {
//   region: 'ap-southeast-2',
//   userPoolId:'ap-southeast-2_XRNQy8BLU',
//   userPoolWebClientId:'9pm3p1bneqq7e8k617lsointi',
//   mandatorySignIn: true,
//   authenticationFlowType: 'USER_SRP_AUTH'
// };

// amplify["API"] = {
//   endpoints: []
// }
const myAppConfig = {
  Auth:{
    region: 'ap-southeast-2',
    userPoolId:'ap-southeast-2_XRNQy8BLU',
    userPoolWebClientId:'9pm3p1bneqq7e8k617lsointi',
    mandatorySignIn: true,
    authenticationFlowType: 'USER_SRP_AUTH',
  },
  API:{
    endpoints: [
      {
        name: "MyAPI",
        endpoint: "https://api.dev.demo.agriporter.com",
        custom_header: async () => {
          // return { Authorization : 'token' }
          // Alternatively, with Cognito User Pools use this:
          return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
        }
      }
    ]
  }

}

Amplify.configure(myAppConfig);//amplify);


if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
