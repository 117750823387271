import { Component, OnInit, ViewChild, AfterViewInit,ChangeDetectorRef} from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { API, Auth } from 'aws-amplify';
import { AmplifyService }  from 'aws-amplify-angular';
import { RouterModule } from '@angular/router';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { AiService } from '../../services/AI/ai.service';
import { Subscription } from 'rxjs';
import {DomSanitizer} from '@angular/platform-browser';
import * as moment from 'moment';


export interface session{
  cidr_removed_at:string;
  client_pic:string;
  created_at:string;
  ewe_breed:string;
  ewe_category:string;
  session_id:string;
  label:string;
  location:{
    country_code:string;
    locality:string;
    open_location_code:string;
    postal_code:string;
    region:string;
  }
  nutrition_status:string;
  pmsg_dose:string;
  xylazine_dose:string;
}


@Component({
  selector: 'app-session-history',
  templateUrl: './session-history.component.html',
  styleUrls: ['./session-history.component.scss'],
  animations: [

    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),

    trigger('slideFromLeft', [
      transition('void => *', [
        style({
          transform: 'translateX(-50vw)',
          opacity:0,
          // width:'50px',
          height:'50px'

        }),
        animate('600ms cubic-bezier(.68,.16,.26,.93)')
      ])
    ]),


    trigger('slideFromBottom', [

      transition('void => *', [
        style({
          transform: 'translateY(30vh)',
          opacity:0,
        }),
        animate('600ms cubic-bezier(.99,0,.45,.71)')
      ])

    ]),


  ],
})




export class SessionHistoryComponent implements OnInit, AfterViewInit {
  /////////////////////////////////////////////////////////////////////////////

  /* Viewing Elements from DOM */
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  ////////////////////////////////////////////////////////////////////////////

  /*VARIABLES*/
  googleApiSrc;           // Stores the constructed Google API
  sessions;               // Contains an array of objects, where the objects are each session.
  showMap:boolean=false;  // Toggle for showing map
  showGraphs=false;
  showXylazine = false;
  showPmsg = false;
  isLoading:boolean = true;
  missingMessage='N/A';
  private sessionsSub:Subscription;
  showMissingSessions=false;

  /*Variables for table*/
  expandedElement;        // Toggling elemnt from the table in DOM
  displayedColumns: string[] = ['created_at','spacer', 'label', 'client_pic', 'location.locality','arrow']; //  Table columns
  dataSource;             // Stores the table data
  pageSizeOptions = [5, 10, 25, 100];

  /*Variables for graphs*/
  pmsgDosage = [];
  xylazineDosage = [];
  graphLabels=[];
  labelX = 'Sessions';
  labelYPMSG = 'Dosage (ml)';
  labelYXylazine = 'Dosage (mg)'
  public colorPallete = ["#73C04C","#DA6A2D","#fc7978","#c299fc","#b2fcff"];

  ////////////////////////////////////////////////////////////////////////////

  /*Constructor */

  constructor(
    private amplifyService:AmplifyService,
    public aiService:AiService,
    private sanitizer: DomSanitizer,
    private cd: ChangeDetectorRef
  ){}

  ////////////////////////////////////////////////////////////////////////////

  /*Bar Chart*/
  // barChartData -> PMSG
  // barChartData2 -> Xylazine

  public barChartOptions1 = {
    
    legend: {
      display: false
    },
    scaleShowVerticalLines: true,
    responsive: true,
    scales: {
      xAxes: [{
          ticks:{
            display:false
          },
          scaleLabel: {
            display: true,
            labelString: this.labelX
          }
      }],
      yAxes: [{
          ticks:{
            beginAtZero:true
          },
          scaleLabel: {
            display: true,
            labelString: this.labelYPMSG
          }
      }]
    },
    tooltips: {
       enabled: true
    },
    plugins: {
      datalabels: {
          display: false,
      }
    }
  };

  public barChartOptions2 = {
    scaleShowVerticalLines: true,
    responsive: true,
    scales: {
      xAxes: [{
          ticks:{
            display:false
          },
          scaleLabel: {
            display: true,
            labelString: this.labelX
          }
      }],
      yAxes: [{
          ticks:{
            beginAtZero:true
          },
          scaleLabel: {
            display: true,
            labelString: this.labelYXylazine
          }
      }]
    },
    legend:{
      display:false
    },
    tooltips: {
       enabled: true
    },
    plugins: {
      datalabels: {
          display: false,
      }
    }
  };

  public barChartLabels = this.graphLabels;
  public barChartType = 'bar';
  public barColors = ["black"]; //removes the different color on hover
  public barChartData = [
  {
    data: this.pmsgDosage,
    backgroundColor: this.colorPallete[0],
    label: 'PMSG',

  },
  ];
  public barChartData2 = [
    {
      data: this.xylazineDosage,
      backgroundColor: this.colorPallete[1],
      label: 'Xylazine'
    },
  ];

  //////////////////////////////////////////////////////////////////////////////////
///
  ngOnInit() {

    this.loadingTimeout();


    this.aiService.getSessions();
    this.sessionsSub = this.aiService.getSessionsObs()
    .subscribe(
      (sessionData:session[])=>{
      this.sessions=sessionData;

      if(this.sessions[0].session_id!==null){
        // Table data initialisations

        this.showMissingSessions = false;
        console.log(this.sessions);

        this.dataSource = new MatTableDataSource(this.sessions);
        this.initialisation();

        this.refreshTable();
        this.isLoading=false;
        this.cd.markForCheck();
      }

    });
  }



  ngAfterViewInit(){

  }

  ngOnDestroy(){
    this.sessionsSub.unsubscribe();
    this.aiService.clear();
  }

  loadingTimeout(){
    setTimeout(()=>{

      if(this.isLoading){
       this.showMissingSessions = true;
      }
     }
      , 2000);
  }

  reloadData(){
    this.aiService.getSessions();
    this.showMissingSessions=false;
    this.loadingTimeout();
  }

  dateFormat(dateInput:any){
    let formatedDate;
    formatedDate = moment.utc(dateInput).format('DD-MM-YYYY  HH:mm:ss');
    return formatedDate
  }

  initialisation(){
    this.googleApiSrc='https://maps.googleapis.com/maps/api/staticmap?size=1080x256&maptype=roadmap&markers=size:mid%7Ccolor:red';
    // Pushing the doses into array
      this.sessions.forEach(data=>{

        let loc={...data.location};
        if(loc.locality&& loc.region && loc.country){
          loc.locality=loc.locality.replace(/\s/g, '+');
          loc.region = loc.region.replace(/\s/g, '+');
          this.googleApiSrc=this.googleApiSrc+'%7C'+loc.locality+','+loc.region+','+loc.postal_code+','+loc.country;
          this.showMap=true;
        }

      })
      this.googleApiSrc +='&key=AIzaSyDvPfyQY_JUXHP1Ax048eVVKth8URpwdm4'
      this.googleApiSrc=this.sanitize(this.googleApiSrc);

  }


  refreshTable(){
    this.paginator.showFirstLastButtons=true;
    this.dataSource.paginator = this.paginator;
    // Custom sorting query
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch(property) {
        case 'location.locality': return item.location.locality;
        default: return item[property];
      }
    };
    this.dataSource.sort = this.sort;
    this.refreshPlots();
  }


  //Check for other implementations
  sanitize(url:string){
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  onPageChange(){
    this.refreshPlots();
  }


  refreshPlots(){

    let data = this.dataSource.sortData(this.dataSource.filteredData,this.dataSource.sort);

    this.pmsgDosage = [];
    this.xylazineDosage = [];
    this.graphLabels=[];

    for(var i = this.paginator.pageIndex*this.paginator.pageSize; i < (this.paginator.pageIndex+1)*this.paginator.pageSize; i++){

      if (i<data.length){

        if (!this.showXylazine){
          this.showXylazine = data[i].xylazine_dose?true:false;
          this.showGraphs=true;
        }

        if (!this.showPmsg){
          this.showPmsg = data[i].pmsg_dose?true:false;
          this.showGraphs=true;
        }

        this.pmsgDosage.push(parseFloat(data[i].pmsg_dose));
        this.xylazineDosage.push(parseFloat(data[i].xylazine_dose));
        this.graphLabels.push(data[i].label)
      }
    }

    this.barChartData[0].data= this.pmsgDosage;
    this.barChartData2[0].data= this.xylazineDosage;
    this.barChartLabels = this.graphLabels;

  }


}
